<template>
  <v-container fluid>
    <v-tabs class="elevation-1 table-wrapper">
      <v-tab
        :to="{ name: 'projectOrderStatistics', query: this.projectOrderStatisticsFilterParams }"
        >{{ $t('general.project_order_statistics') }}</v-tab
      >
      <v-tab
        :to="{ name: 'contactOrderStatistics', query: this.contactOrderStatisticsFilterParams }"
        >{{ $t('general.contact_order_statistics') }}</v-tab
      >
      <v-tab :to="{ name: 'orderStatisticsByDate', query: this.orderStatisticsByDateFilterParams }">
        {{ $t('general.order_statistics_by_date') }}
      </v-tab>
      <v-tabs-items>
        <router-view />
      </v-tabs-items>
    </v-tabs>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'OrderStatisticsTabs',

  computed: {
    ...mapState('projectOrderStatistics', ['projectOrderStatisticsFilterParams']),
    ...mapState('contactOrderStatistics', ['contactOrderStatisticsFilterParams']),
    ...mapState('orderStatisticsByDate', ['orderStatisticsByDateFilterParams']),
  },
};
</script>

<style scoped></style>
